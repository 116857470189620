var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',{staticClass:"backBox",attrs:{"title":"我的优惠券","right-text":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"page"},[_c('header',[_c('div',{staticClass:"query_box"},[_c('div',{staticClass:"query_box_item"},[_c('span',{staticClass:"query_box_item_tit"},[_vm._v("车牌号码")]),_c('div',{staticClass:"query_box_item_right"},[_c('div',{staticClass:"section"},[_c('span',{staticClass:"selPlateNum",on:{"click":_vm.openVhPopup}},[_vm._v(_vm._s(_vm.selVhVal ? _vm.selVhVal : '选择车牌号码'))]),_c('img',{staticClass:"rightIcon",attrs:{"src":require("../../../assets/img/rightjt@2x.png")}}),_c('van-popup',{attrs:{"position":"bottom"},on:{"close":_vm.onVhClose},model:{value:(_vm.showVhPopup),callback:function ($$v) {_vm.showVhPopup=$$v},expression:"showVhPopup"}},[_c('van-picker',{attrs:{"columns":_vm.vhColumns,"show-toolbar":"","title":"选择车牌号码","visible-item-count":"5","default-index":"0"},on:{"change":_vm.onVhChange,"confirm":_vm.onVhConfirm,"cancel":_vm.onVhCancel}})],1)],1)])])])]),_c('div',{staticClass:"box_2"},[_c('van-tabs',{attrs:{"active":_vm.active,"sticky":"","color":"#4797EA","title-active-color":"#4797EA"},on:{"change":_vm.onChange}},[_c('van-tab',{attrs:{"title":"现有优惠券"}},[_c('span',{staticClass:"text_3"},[_vm._v("共"+_vm._s(_vm.couponHaveList.length)+"张")]),_c('div',{staticClass:"section_6"},[_vm._l((_vm.couponHaveList),function(item){return _c('div',{key:item.id,class:[
                  'box_4',
                  _vm.getClassByType(item)
                ],on:{"click":function($event){return _vm.openCouponDetail(item,1)}}},[_c('div',{staticClass:"leftBox"},[_c('div',{class:[
                      'text-group_13',
                      _vm.getClassByType(item)
                    ]},[_c('div',{staticClass:"text-wrapper_1"},[_c('span',{staticClass:"text_4"},[_vm._v(_vm._s(item.type == 1 || item.type == 11 ? item.value / 100 : item.type == 3 || item.type == 13 ? 1 : item.value))]),_c('span',{staticClass:"text_5"},[_vm._v(_vm._s(_vm.getUnitByType(item)))])]),_c('span',{staticClass:"text_33"},[_vm._v(_vm._s(item.vh_p_number))])]),_c('div',{staticClass:"lineline"})]),_c('div',{staticClass:"rightBox"},[_c('div',{staticClass:"text-group_14"},[_c('span',{staticClass:"text_6"},[_vm._v(_vm._s(_vm.getNameByType(item)))]),_c('span',{staticClass:"text_7"},[_vm._v(_vm._s(item.biz_name))]),_c('span',{staticClass:"text_8"},[_vm._v("领券时间："+_vm._s(_vm.timeFormatter(item.granted_time)))]),_c('span',{staticClass:"text_8"},[_vm._v("失效时间："+_vm._s(_vm.timeFormatter(item.expire_time)))])])])])}),(_vm.couponHaveList.length == 0)?_c('van-empty',{attrs:{"description":"您还没有优惠券"}}):_vm._e(),(_vm.couponHaveList.length !== 0 && _vm.isError)?_c('van-empty',{attrs:{"image":"error","description":"优惠券查询异常"}}):_vm._e()],2)]),_c('van-tab',{attrs:{"title":"已使用/过期"}},[_c('span',{staticClass:"text_3"},[_vm._v("共"+_vm._s(_vm.couponDoneList.length)+"张 ")]),_c('div',{staticClass:"section_6"},[_vm._l((_vm.couponDoneList),function(item){return _c('div',{key:item.id,class:[
                  'box_4','overDate',
                  _vm.getClassByType(item)
                ],on:{"click":function($event){return _vm.openCouponDetail(item,2)}}},[_c('div',{staticClass:"leftBox"},[_c('div',{class:[
                      'text-group_13',
                      _vm.getClassByType(item)
                    ]},[_c('div',{staticClass:"text-wrapper_1"},[_c('span',{staticClass:"text_4"},[_vm._v(_vm._s(item.type == 1 || item.type == 11 ? item.value / 100 : item.value))]),_c('span',{staticClass:"text_5"},[_vm._v(_vm._s(_vm.getUnitByType(item)))])]),_c('span',{staticClass:"text_33"},[_vm._v(_vm._s(item.vh_p_number))])]),_c('div',{staticClass:"lineline"})]),_c('div',{staticClass:"rightBox"},[_c('div',{staticClass:"text-group_14"},[_c('span',{staticClass:"text_6"},[_vm._v(_vm._s(_vm.getNameByType(item)))]),_c('span',{staticClass:"text_7"},[_vm._v(_vm._s(item.biz_name))]),_c('span',{staticClass:"text_8"},[_vm._v("领券时间："+_vm._s(_vm.timeFormatter(item.granted_time)))]),(!item.latest_used_time)?_c('span',{staticClass:"text_8"},[_vm._v("失效时间："+_vm._s(_vm.timeFormatter(item.expire_time)))]):_vm._e(),(item.latest_used_time)?_c('span',{staticClass:"text_8"},[_vm._v("使用时间："+_vm._s(_vm.timeFormatter(item.latest_used_time)))]):_vm._e()])]),_c('div',{staticClass:"box_gq"},[_c('div',{class:[
                      'box_10',
                      _vm.getClassByType(item)
                    ]},[_c('div',{class:[
                        'text-wrapper_9',
                        _vm.getClassByType(item)
                      ]},[_c('span',{staticClass:"text_27",class:[
                          'text_27',
                          _vm.getClassByType(item)
                        ]},[_vm._v(" "+_vm._s(item.status === 2 ? '使用' : item.status === 3 ? '回收' : item.status === 4 ? '过期' : '')+" ")])])])])])}),(_vm.couponDoneList.length == 0)?_c('van-empty',{attrs:{"description":"暂无匹配数据"}}):_vm._e(),(_vm.couponDoneList.length !== 0 && _vm.isError)?_c('van-empty',{attrs:{"image":"error","description":"查询异常"}}):_vm._e()],2)])],1)],1)]),_c('van-toast',{attrs:{"id":"van-toast"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }